// (c) Studio Verb

$font-family-serif: source-serif-pro;
$font-family-sans-serif: barlow;
$font-size-extra-small: 13.5px;
$font-size-small: 14.5px;
$font-size-medium: 17.5px;
$font-size-large: 20px;
$font-size-extra-large: 65px;
$font-weight-serif-light: 400;
$font-weight-serif-normal: 400;
$font-weight-serif-bold: 600;
$font-weight-sans-serif-light: 100;
$font-weight-sans-serif-normal: 300;
$font-weight-sans-serif-bold: 600;

@font-face {
  font-family: source-serif-pro;
  src: url(/assets/fonts/SourceSerifPro-Regular.ttf) format('opentype');
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: source-serif-pro;
  src: url(/assets/fonts/SourceSerifPro-Bold.ttf) format('opentype');
  font-style: normal;
  font-weight: 600; }

@font-face {
  font-family: barlow;
  src: url(/assets/fonts/BarlowCondensed-Thin.ttf) format('opentype');
  font-style: normal;
  font-weight: 100; }

@font-face {
  font-family: barlow;
  src: url(/assets/fonts/BarlowCondensed-ThinItalic.ttf) format('opentype');
  font-style: italic;
  font-weight: 100; }

@font-face {
  font-family: barlow;
  src: url(/assets/fonts/BarlowCondensed-Regular.ttf) format('opentype');
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: barlow;
  src: url(/assets/fonts/BarlowCondensed-Italic.ttf) format('opentype');
  font-style: italic;
  font-weight: 300; }

@font-face {
  font-family: barlow;
  src: url(/assets/fonts/BarlowCondensed-Bold.ttf) format('opentype');
  font-style: normal;
  font-weight: 600; }

@font-face {
  font-family: barlow;
  src: url(/assets/fonts/BarlowCondensed-BoldItalic.ttf) format('opentype');
  font-style: italic;
  font-weight: 600; }

@include labels('body') {
  @include font('s', 'default', 'l');
  color: get-color('dark-grey');
  letter-spacing: .2px;
  line-height: 1.6em;

  + h1, + h2, + h3, + h4, + h5, + h6 {
    margin-top: 40px; }
  + p {
    margin-top: 20px; } }

@include links() {
  color: get-color('greyish-blue');

  @include transition(opacity, .3s, ease-out) {
    opacity: 1;

    @include state('hover') {
      opacity: .5; } } }

h1 { //
  @include font('ss', 'xl', 'n');
  letter-spacing: 5px;
  line-height: 1.2em;
  text-transform: uppercase;
  + p {
    margin-top: 30px; } }

h2 { // i.e. Case Study page titles
  @include display('(block)');
  @include font('ss', 'xl', 'n');
  @include padding(30px 0 0 0);
  // border-top: 2px solid get-color('black')
  letter-spacing: 5px;
  line-height: 1.2em;
  text-transform: uppercase; }

h3 {  //
  @include display('(block)');
  @include font('ss', 's', 'b');
  letter-spacing: 4px;
  text-align: center;
  text-transform: uppercase;

  + p {
    margin-top: 20px; } }

h4 { // i.e. Case Study page titles
  @include font('ss', 'xl', 'l');
  letter-spacing: 8px;
  line-height: 1.2em;
  text-transform: uppercase;
  + p {
    margin-top: 30px; } }

h5 { // i.e. Case Study entry titles
  @include font('ss', 'm', 'n');
  letter-spacing: 2px;
  text-transform: uppercase;
  + p {
    margin-top: 12px; } }

h6 { // i.e. Homepage definition titles
  @include font('s', 's', 'l');
  font-style: italic;
  + p {
    margin-top: 10px; } }
