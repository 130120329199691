// (c) Studio Verb

@include element('page-case-studies') {
  @include align();
  @include overflow(visible hidden);
  @include size(100%);
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none; }

  cursor: grab;

  @include state('scrolling') {
    cursor: grabbing; }

  @include before-transition() {
    @include name('entries') {
      opacity: 0;

      @include element('case-study-entry') {
        @include name('content') {
          transform: translate3d(0, -100vh, 0); } } } }

  @include name('instructions') {
    @include align(fixed);
    @include size(100%);
    @include pointable(false);
    @include display('boxv');
    opacity: 0;
    background: rgba(get-color('white'), 0.8);

    @include icons() {
      @include margin(0 0 10px);
      @include size(40px);
      @include icon-style('dark');
      @include display(block); } }

  @include name('entries') {
    @include display('(box>)', top left);
    @include size(auto 100vh);
    background: url(/assets/images/case-studies/background-repeat-fat.png) repeat;
    perspective: 800px;

    @include screen('h>=%s', 500px) {
      background: url(/assets/images/case-studies/background-repeat-slim.png) repeat; } }

  @include name('covers') {
    @include align(fixed);
    @include size(100vw 100vh);
    perspective: 200px;

    @include name('cover') {
      @include align();
      @include pointable(false);
      @include size(100%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: $mini-cover-transition;

      @include state('none', true) {
        opacity: 0;
        transform: translate3d(0, 0, 50px); }

      @include state('active') {
        opacity: 1;
        transform: translate3d(0, 0, 0); } } } }
