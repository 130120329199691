// (c) Studio Verb

/// Gets the z-index of the specified layer.
///
/// @param {string|number} $index ['base'] - Specify a value or pick from a
///                                          predefined set.
@function get-z($index:'base') {
  @if ($index == 'base') {
    @return 0; }
  @else if ($index == 'content') {
    @return 1; }
  @else if ($index == 'overlay') {
    @return 10; }
  @else if ($index == 'local-nav') {
    @return 50; }
  @else if ($index == 'global-nav') {
    @return 100; }
  @else {
    @return $index; } }

@mixin before-transition() {
  @content; }
