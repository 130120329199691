// (c) Studio Verb

$global-nav-item-count: 4;

nav#global-nav {
  @include align(top left fixed);
  @include display('boxv', left center);
  @include size(100%);
  background: get-color('black');
  z-index: get-z('global-nav');

  .line {
    @include align(top left);
    @include edge(0 0 0 90%);
    @include size(1px 100%);
    background: get-color('white', .1); }

  .menu {
    @include edge(0 0 0 90%);
    margin-left: #{-200px}; }

  @include name('copyright') {
    @extend %box-reset;
    @include display('block');
    @include font('ss', 'xs', 'n');
    @include padding(0 15px 0 0);
    @include size(200px auto);
    color: get-color('white', .2);
    letter-spacing: 2px;
    text-align: right;
    text-transform: uppercase; }

  @include name('home-button') {
    @include icons() {
      @include icon-style('light');
      @include transition(opacity, .6s, ease-out);
      opacity: .3; }

    @include state('hover') {
      @include icons() {
        opacity: 1; } }

    &:disabled, &[disabled] {
      @include pointable(false);
      display: none; } }

  @include element('reactive-a') {
    @include display('box>', right center);
    @include padding(0 15px 0 0);
    @include size(200px 30px);
    @include space-between(20px, 'v');

    &:not([name='home-button']) {
      @include state('hover' 'active') {
        @include labels() {
          color: get-color('white');
          transform: translate3d(-10px, 0, 0); }

        &::before {
          background: get-color('white');
          transform: rotate(765deg); } }

      @include state('active') {
        @include pointable(false); }

      @include labels() {
        @include font('ss', 'l', 'n');
        @include transition(transform, .4s, ease-out);
        color: get-color('white');
        letter-spacing: 4px;
        text-transform: uppercase;
        transform: translate3d(0, 0, 0); }

      &::before {
        @include align();
        @include edge(#{(30px - 10px)/2} 0 0 #{200px - 10px/2 - 1px});
        @include size(10px);
        @include transition(background transform, .4s, cubic-bezier(.29,1.48,.24,.96));
        background: get-color('black');
        border: 2px solid get-color('white', .2);
        content: '';
        transform: rotate(45deg);
        transform-origin: center; } } }

  @include state('none', true) {
    @include pointable(false);

    @include transition(transform, .6s, cubic-bezier(.81,.01,0,.96), .2s) {
      transform: translate3d(0, 100%, 0); }

    .line {
      @include transition(transform, .2s, ease-out, 0s) {
        transform: translate3d(0, 100%, 0); } }

    .menu > * {
      @include transition(opacity transform, .4s, ease-out) {
        opacity: 0;
        transform: translate3d(0, 50px, 0);

        @for $i from 1 through $global-nav-item-count {
          &:nth-child(#{$i}) {
            transition-delay: #{($global-nav-item-count - $i) * .1}s; } } } } }

  @include state('active') {
    @include pointable(true);

    @include transition(transform, .6s, cubic-bezier(.81,.01,0,.96), 0s) {
      transform: translate3d(0, 0, 0); }

    .line {
      @include transition(transform, .2s, ease-out, .4s) {
        transform: translate3d(0, 0, 0); } }

    .menu > * {
      @include transition(opacity transform, .4s, ease-out) {
        opacity: 1;
        transform: translate3d(0, 0, 0);

        @for $i from 1 through $global-nav-item-count {
          &:nth-child(#{$i}) {
            transition-delay: #{($i - 1) * .1 + .6}s; } } } } } }
