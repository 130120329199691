// (c) Studio Verb

@include element('micro-paginator') {
  &, &.horizontal {
    @include display('box>');

    @include name('node') {
      @include space-between(10px, '>'); } }

  &.vertical {
    @include display('boxv');

    @include name('node') {
      @include margin(null 0px null null);
      @include space-between(10px, 'v'); } }

  @include name('node') {
    @include size(2px, true);
    @include transition(background, .4s, ease-out);

    @include state('none', true) {
      background: get-color('light-grey'); }

    @include state('active' 'hover') {
      background: get-color('black'); } }

  &.large {
    &, &.horizontal {
      @include name('node') {
        @include space-between(7px, '>'); } }

    &.vertical {
      @include name('node') {
        @include margin(null 0px null null);
        @include space-between(7px, 'v'); } }

    @include name('node') {
      @include size(5px, true); } }

  &.white {
    @include name('node') {
      @include state('none', true) {
        background: get-color('white', .2); }
      @include state('active' 'hover') {
        background: get-color('white'); } } } }
