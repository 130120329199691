// (c) Studio Verb

header {
  @include align($type:'fixed');
  @include display('boxv', top center);
  @include padding(20px 10vw);
  z-index: get-z('global-nav');

  @include screen('>%s', 300px) {
    @include padding(20px 30px); }

  @include buttons() {
    @include space-between(15px, 'v'); }

  @include name('home-button') {
    @include size(50px);
    background: transparent;
    font-size: 0;

    @include animation(home-button-transition-in, .7s, cubic-bezier(.29,1.48,.24,.96), 0s, 1, normal, forwards) {
      transform: translate3d(0, -100px, 0);

      @keyframes home-button-transition-in {
        to {
          transform: translate3d(0, 0, 0); } } }

    &, &.dark {
      svg {
        .filling {
          @include transition(fill stroke, .4s, ease-out);
          fill: get-color('black'); }
        .frame {
          @include transition(fill stroke, .4s, ease-out);
          // fill: get-color('white')
          stroke: get-color('black'); } } }

    &.light {
        .filling {
          fill: get-color('white'); }
        .frame {
          // fill: get-color('black')
          stroke: get-color('white'); } }

    @include state('none', true) {
      @include name('monogram') {
        @include pointable(false); } }

    @include name('monogram') {
      @include align();
      @include overflow(hidden);
      @include size(100%); } }

  @include name('menu-button') {
    @include button-style('rotating');

    @include animation(header-button-transition-in, 1s, cubic-bezier(.29,1.48,.24,.96), 0s, 1, normal, forwards) {
      opacity: 0;
      transform: translate3d(-50px, 0, 0);

      @keyframes header-button-transition-in {
        to {
          opacity: 1;
          transform: translate3d(0, 0, 0); } } } } }
