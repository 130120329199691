// (c) Studio Verb

@function get-color($name, $alpha:1) {
  @if ($name == 'black') {
    @return rgba(#000000, $alpha); }
  @else if ($name == 'very-dark-grey') {
    @return rgba(#333333, $alpha); }
  @else if ($name == 'dark-grey') {
    @return rgba(#666666, $alpha); }
  @else if ($name == 'grey') {
    @return rgba(#999999, $alpha); }
  @else if ($name == 'light-grey') {
    @return rgba(#cccccc, $alpha); }
  @else if ($name == 'very-light-grey') {
    @return rgba(#eeeeee, $alpha); }
  @else if ($name == 'white') {
    @return rgba(#ffffff, $alpha); }
  @else if ($name == 'red') {
    @return rgba(#ec506d, $alpha); }
  @else if ($name == 'greyish-blue') {
    @return rgba(#515778, $alpha); }
  @else {
    @return transparent; } }
