// (c) Studio Verb

@mixin icon-style($style:'dark') {
  &.email {
    background-image: url(/assets/images/common/email-icon.svg); }
  &.instagram {
    background-image: url(/assets/images/common/instagram-icon.svg); }
  &.github {
    background-image: url(/assets/images/common/github-icon.svg); }
  &.menu {
    background-image: url(/assets/images/common/menu-icon.svg); }
  &.exit {
    background-image: url(/assets/images/common/exit-icon.svg); }
  &.drag {
    background-image: url(/assets/images/common/drag-icon.svg); }

  @if $style == 'light' {
    @include image-map-item(null, 0, 1, 1, 2); }
  @else {
    @include image-map-item(null, 0, 0, 1, 2); } }

@include icons() {
  @include size(16px);
  background-repeat: no-repeat;
  background-size: 200% 100%;
  &, &.dark {
    @include icon-style('dark'); }
  &.light {
    @include icon-style('light'); } }
