// (c) Studio Verb

$case-study-compact-threshold: 450px;
$case-study-contents-body-text-padding: 20px;
$case-study-contents-header-text-padding: 50px;

@mixin body-text-padding($padding:$case-study-contents-body-text-padding) {
  + p, + ol, + ul {
    @include margin($padding null null null); } }

@mixin header-text-padding($padding:$case-study-contents-header-text-padding) {
  + h1, + h2, + h3, + h4, + h5, + h6 {
    @include margin($padding null null null); } }

@mixin content-padding($paddingV:40px, $paddingH:10vw) {
  @include padding($paddingV $paddingH);
  @include screen('>%s', $case-study-compact-threshold) {
    @include padding(#{$paddingV * 1.5} $paddingH); } }

@mixin body-text-font-style() {
  @include font('s', 'default', 'l');
  line-height: 1.5em;
  color: get-color('dark-grey'); }

@include element('page-case-study') {
  @include name('contents') {
    @include display('boxv', top left);
    transform: translate3d(0, 0, 0);

    div.slice {
      @include size(100% auto);

      @include data('slicetype', 'left-aligned-text') {
        @include content-padding();
        @include display('boxv', top left);
        align-self: flex-start;

        p, ol, ul, h1, h2, h3, h4, h5, h6 {
          @include max-size(300px none);
          text-align: left; } }

      @include data('slicetype', 'right-aligned-text') {
        @include content-padding();
        @include display('boxv', top right);
        align-self: flex-end;

        p, ol, ul, h1, h2, h3, h4, h5, h6 {
          @include max-size(300px none);
          text-align: right; } }

      @include data('slicetype', 'text-beside-image') {
        @include display('boxv', top left);

        > * {
          @include display('boxv');

          @include data('field', 'text') {
            @include content-padding();
            @include display('boxv', top left);

            p, ol, ul, h1, h2, h3, h4, h5, h6 {
              @include max-size(300px none);
              text-align: left; } }

          @include data('field', 'image') {
            @include content-padding();
            @include display('elasticv');
            img {
              @include align();
              @include display('flex');
              @include size(100%); } } }

        @include screen('>%s', $case-study-compact-threshold) {
          @include display('elastic>', top left); } }

      @include data('slicetype', 'image-beside-text') {
        @include display('boxv', top right);

        > * {
          @include display('boxv');

          @include data('field', 'text') {
            @include content-padding();
            @include display('boxv', top right);

            p, ol, ul, h1, h2, h3, h4, h5, h6 {
              @include max-size(300px none);
              text-align: right; } }

          @include data('field', 'image') {
            @include content-padding();
            @include display('elasticv');
            img {
              @include align();
              @include display('flex');
              @include size(100%); } } }

        @include screen('>%s', $case-study-compact-threshold) {
          @include display('elastic>', top left); } }

      @include data('slicetype', 'gallery') {
        @include display('boxv', top left);
        @include size(100% auto);

        @include screen('>%s', $case-study-compact-threshold) {
          @include display('elastic>', top left); }

        @include images() {
          @include display('block');
          @include size(100% auto); } }

      @include data('slicetype', 'quote') {
        @include content-padding(30px, 60px);
        @include max-size(500px none);
        @include margin(10vw 0);
        @include size(80vw auto);
        align-self: center;
        border: 1px solid rgba(get-color('light-grey'), .4); } }

    [data-field='image'] {
      @include transition(opacity transform, .4s, ease-out, .1s);
      opacity: 0;
      transform: translate3d(0, 150px, 0); }

    img {
      @include body-text-padding();
      @include header-text-padding();
      @include size(100% auto);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border: none; }

    h1 {
      @include body-text-padding(60px);
      @include font('ss', 'l', 'n');
      @include header-text-padding(20px);
      border: none;
      letter-spacing: 8px;
      line-height: 1.2em;
      text-align: left;
      text-transform: uppercase; }

    h2 {
      @include body-text-padding(30px);
      @include font('ss', 's', 'b');
      @include header-text-padding(20px);
      border: none;
      font-style: normal;
      letter-spacing: 4px;
      text-align: left;
      text-transform: uppercase; }

    h3 {
      @include body-text-padding(20px);
      @include font('s', 'm', 'l');
      @include header-text-padding(20px);
      border: none;
      letter-spacing: 2px;
      font-style: italic;
      text-align: left;
      text-transform: none; }

    h4 {
      @include body-text-padding(60px);
      @include font('s', 'm', 'l');
      @include header-text-padding(20px);
      @include padding(15px 0 0 0);
      border-top: 1px solid get-color('grey');
      font-style: normal;
      letter-spacing: 4px;
      text-align: left;
      text-transform: none; }

    h5 {
      @include body-text-padding(60px);
      @include font('s', 'm', 'l');
      @include header-text-padding(20px);
      @include padding(15px 0 0 0);
      border-top: 1px solid get-color('grey');
      font-style: normal;
      letter-spacing: 4px;
      text-align: center;
      text-transform: none; }

    h6 {
      @include body-text-padding(60px);
      @include header-text-padding(20px);
      @include font('s', 'm', 'l');
      @include padding(15px 0 0 0);
      border-top: 1px solid get-color('grey');
      font-style: normal;
      letter-spacing: 4px;
      text-align: right;
      text-transform: none; }

    p, li, ol, ul {
      @include body-text-font-style();
      @include body-text-padding();
      @include header-text-padding();
      @include size(100% auto); }

    ol, ul {
      @include padding(null null null 30px); } } }
