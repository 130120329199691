// (c) Studio Verb

div#errors {
  @include display('boxv');
  @include size(100% 100vh);

  @include name('return-button') {
    @include button-style('static');
    @include margin(20px 0 0 0); } }

