// (c) Studio Verb

footer {
  @include align(bottom right fixed);
  @include display('box>');
  @include padding(20px 10vw);
  z-index: get-z('global-nav');

  @include screen('>%s', 300px) {
    @include padding(20px 30px); }

  @include element('reactive-a') {
    @include button-style('rotating');
    @include space-between(15px);

    @for $i from 1 through 10 {
      &:nth-of-type(#{$i}) {
        @include animation(footer-button-transition-in, 1s, cubic-bezier(.29,1.48,.24,.96), #{($i - 1) * .1}s, 1, normal, forwards) {
          opacity: 0;
          transform: translate3d(0, 50px, 0);

          @keyframes footer-button-transition-in {
            to {
              opacity: 1;
              transform: translate3d(0, 0, 0); } } } } } } }
