// (c) Studio Verb

$button-static-height: 25px;

@mixin button-style($style:'rotating') {
  @if ($style == 'rotating') {
    @include display('box>');
    @include size(32px, true);
    @include transition(transform background, .4s, cubic-bezier(.29,1.48,.24,.96));

    @include icons() {
      display: inline-flex;
      position: static; }

    &, &.dark {
      @include state('none', true) {
        background: get-color('black');

        @include icons() {
          @include icon-style('light'); } }

      @include state('hover') {
        background: get-color('white');

        @include icons() {
          @include icon-style('dark'); } } }

    &.light {
      @include state('none', true) {
        background: get-color('white');

        @include icons() {
          @include icon-style('dark'); } }

      @include state('hover') {
        background: get-color('black');

        @include icons() {
          @include icon-style('light'); } } }

    @include state('none', true) {
      @include icons() {
        @include transition(transform, .8s, cubic-bezier(.29,1.48,.24,.96)) {
          transform: rotate(0deg); } } }

    @include state('hover') {
      @include icons() {
        @include transition(transform, .8s, cubic-bezier(.29,1.48,.24,.96)) {
          transform: rotate(360deg); } } } }

  @else if ($style == 'static') {
    @include display('box>');
    @include padding(0 20px);
    @include size(auto $button-static-height);
    @include transition(background, .3s, ease-in-out);

    &, &.dark {
      @include state('none', true) {
        background: get-color('black');
        @include labels() {
          color: get-color('white'); } }

      @include state('hover') {
        background: get-color('white');
        @include labels() {
          color: get-color('black'); } } }

    &.light {
      @include state('none', true) {
        background: get-color('white');
        @include labels() {
          color: get-color('black'); } }

      @include state('hover') {
        background: get-color('black');
        @include labels() {
          color: get-color('white'); } } }

    @include labels() {
      @include font('ss', 'xs', 'n');
      @include transition(color, .3s, ease-out);
      letter-spacing: 2px;
      text-transform: uppercase; } }

  @else if ($style == 'url') {
    @include transition(opacity, .3s, ease-out) {
      @include state('none', true) {
        opacity: 1; }
      @include state('hover') {
        opacity: .5; } } } }
