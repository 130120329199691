// (c) Studio Verb

@include element('page-home') {
  @include display('boxv');
  @include size(100%);
  @include overflow(hidden);

  @include before-transition() {
    @include name('card') {
      opacity: 0;
      transform: scale(.8); }

    @include name('case-studies-button') {
      opacity: 0;
      transform: scale(.8); }

    @include name('scene') {
      opacity: 0; } }

  @include name('scene') {
    @include align(); }

  @include name('card') {
    @include display('flexv');
    @include size(80% auto);
    @include max-size(300px none);

    div.contents {
      @include display('boxv', top left);
      @include size(100% auto);

      @include name('glyph') {
        @include size(100% 15px);
        background: url(/assets/images/common/verb-logo-studio.svg) left center / auto 100% no-repeat;
        font-size: 0; }

      @include name('tense') {
        @include margin(15px 0);
        @include size(auto 8px); }

      div.definitions {
        @include pointable(false);
        @include size(100% 100px);
        perspective: 800px;
        transform: translate3d(0, 0, 0);

        @include name('definition') {
          @include align();
          @include size(100% auto);

          @include transition(transform opacity, .4s, ease-in-out) {
            @include state('none', true) {
              opacity: 0;
              transform: translate3d(-50px, 20px, 1200px); }
            @include state('active') {
              opacity: 1;
              transform: translate3d(0, 0, 0); } }

          p + p {
            margin-top: 6px; } } } }

    @include element('micro-paginator') {
      @include margin(20px 0 0 0);
      @include pointable(false); }

    @include name('progress-bar') {
      @include margin($bottom:40px);
      @include size(0 1px);
      background: get-color('black'); } }

  @include name('case-studies-button') {
    @include button-style('static');
    @include margin(30px 0 0 0); } }
