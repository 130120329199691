// (c) Studio Verb

@include element('page-studio') {
  @include display('boxv');
  @include overflow(hidden);
  @include size(100%);
  @include transition(background, .3s, ease-out);
  background: #eee;

  @include data('index', 1);
  @include data('index', 2);
  @include data('index', 4);
  @include data('index', 3) {
    background: #111; }

  @include screen('>tablet') {
    @include display('flex>', top left); }

  @include before-transition() {
    transform: scale(.8);
    opacity: 0;

    @include name('instruction') {
      transform: translate3d(0, 100px, 0); } }

  @include name('paginator') {
    @include align(right center);
    @include edge(null 15px null null);
    @include pointable(false);
    z-index: 1000; }

  @include name('instruction') {
    @include align(bottom center, fixed);
    @include edge(null null 20px null);
    @include size(20px);

    svg {
      @include size(100%);
      fill: get-color('black');

      #knob {
        fill: get-color('red');

        @include animation(mouse-knob, 1s, ease-out, 0s, infinite) {
          @keyframes mouse-knob {
            0% {
              transform: translate3d(0, 0, 0); }
            25% {
              transform: translate3d(0, 20px, 0); }
            50% {
              transform: translate3d(0, 0, 0); } } } } } }

  @include name('slide') {
    @include align();
    @include display('box<');
    @include pointable(true);
    @include size(100%);
    perspective: 800px;

    @include screen('h>%s', 420px) {
      @include display('boxv'); }

    @include screen('h>%s', 700px);

    &[disabled] {
      @include pointable(false); }

    @include name('figure') {
      @include margin(0 0 0 30px);
      @include max-size(350px);
      @include size(70vh);
      backface-visibility: hidden;
      perspective: 800px;
      transform: translate3d(0, 0, 0);

      @include screen('h>%s', 420px) {
        @include margin(0 0 50px 0);
        @include size(40vh); }

      @include screen('h>%s', 700px) {
        @include size(100vh);
        @include margin(0 0 60px 0); }

      svg {
        @include overflow(visible);
        @include size(100%); } }

    @include name('description') {
      @include display('boxv');
      @include max-size(350px none);
      @include size(40% auto);

      @include labels('header') {
        @include size(100% auto);
        color: get-color('black');
        text-align: center; }

      @include labels('body') {
        @include font('s', 'd', 'l');
        @include size(100% auto);
        color: get-color('black');
        opacity: .6;
        text-align: center;

        @include screen('h>%s', 700px) {
          @include font('s', 'd', 'l'); } }

      @include element('reactive-a') {
        @include margin(30px null null null);

        @include labels() {
          @include transition(opacity, .3s, ease-out); }

        @include state('hover') {
          @include labels() {
            opacity: .2; } } }

      @include screen('h>%s', 420px) {
        @include size(80% auto); }

      @include screen('h>%s', 700px); }

    &.profile {
      @include name('figure') {
        #frame {
          stroke: get-color('black'); }
        #letters {
          fill: get-color('black'); } } }

    &.branding {}

    &.engineering {
      @include name('description') {
        @include labels() {
          color: get-color('white'); } } }

    &.design {}

    &.copywriting {} } }
