// (c) Studio Verb

$entry-slim-width: 250px;
$entry-slim-height: 500px;
$entry-fat-width: 320px;
$entry-fat-height: 100%;
$cover-slim-width: 120px;
$cover-slim-height: 300px;
$cover-fat-width: 120px;
$cover-fat-height: 100%;
$description-offset: 20px;
$mini-cover-transition: all 1s cubic-bezier(.29,1.48,.24,.96);

@include element('case-study-entry') {
  @include display('box>', top left);
  @include overflow(hidden);
  @include size($entry-fat-width 100%);
  @include transition(transform opacity background, 0.3s, ease-out);

  @for $i from 1 through 20 {
    &:nth-of-type(#{$i}) {
      transition-delay: #{($i - 1) * .1}s; } }

  &, &.top {
    @include name('content') {
      @include display('boxv', top left);
      flex-wrap: wrap; }

    &:first-child {
      @include name('content') {
        @include display('boxv', center left);
        flex-wrap: wrap; } } }

  &.mid {
    @include name('content') {
      @include display('boxv', center left);
      flex-wrap: wrap; } }

  &.bot {
    @include name('content') {
      @include display('box^', bottom left);
      flex-wrap: wrap; }

    &:first-child {
      @include name('content') {
        @include display('boxv', center left);
        flex-wrap: wrap; } } }

  @include state('none', true) {
    background: transparent;
    opacity: 1;
    transform: translate3d(0, 0, 0); }

  @include state('hidden') {
    background: transparent;
    opacity: 0;
    transform: translate3d(0, 0, -20px);
    transition-delay: 0s;

    @include name('content') {
      @include name('case-study-button') {
        @include pointable(false); } } }

  @include state('active') {
    background: get-color('white', .98);
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-delay: 0s;

    @include name('content') {
      div.cover {
        @include size(0, null); } } }

  @include name('content') {
    @include size(100% $entry-fat-height);

    div.cover {
      @include overflow(hidden);
      @include pointable(false);
      @include size($cover-fat-width $cover-fat-height);
      transition: $mini-cover-transition;

      figure {
        @include size($entry-slim-width 100%);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; } }

    div.description {
      @include max-size(none none);
      @include overflow(hidden);
      @include padding(40px 35px);
      @include pointable(false);
      @include size(($entry-fat-width - $cover-fat-width - $description-offset) auto);
      @include transition(transform, .6s, ease-in-out);

      @include labels('body') {
        @include font('s', 'xs', 'l'); } }

    @include name('case-study-button') {
      @include button-style('static'); } }

  @include screen('h>=%s', 500px) {
    @include size($entry-slim-width 100%);

    &, &.top {
      @include name('content') {
        @include display('boxv', top left);
        align-self: flex-start; }

      &:first-child {
        @include name('content') {
          @include display('boxv', top left);
          align-self: center; } } }

    &.mid {
      @include name('content') {
        @include display('boxv', top left);
        align-self: center; } }

    &.bot {
      @include name('content') {
        @include display('box^', bottom left);
        align-self: flex-end; }

      &:first-child {
        @include name('content') {
          @include display('boxv', top left);
          align-self: center; } } }

    @include name('content') {
      @include size(100% $entry-slim-height);

      div.description {
        @include max-size(none ($entry-slim-height - $cover-slim-height - $button-static-height));
        @include size(90% auto); }

      div.cover {
        @include size($cover-slim-width $cover-slim-height); } } } }
