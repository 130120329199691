/* Base */

#awwwards{
	position: fixed;
	top: 20px;
	width: 90px;
	height: 135px;
	text-indent: -666em;
	overflow: hidden;
	z-index: 999;

	-webkit-transition: all 1s ease;
    transition: all 1s ease;
}
#awwwards.top{
	top: 20px;
}
#awwwards.left{
	left: 0;
}
#awwwards.right{
	right: 0;
}

#awwwards a{
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 90px;
	height: 135px;
	background-repeat: no-repeat;
	background-size: 90px 135px;
}

/* HONORABLE */

#awwwards.honorable.black.left a{
	background-image: url(/assets/images/awwwards/awwwards_honorable_black_left.png);
}
#awwwards.honorable.black.right a{
	background-image: url(/assets/images/awwwards/awwwards_honorable_black_right.png);
}
#awwwards.honorable.silver.left a{
	background-image: url(/assets/images/awwwards/awwwards_honorable_silver_left.png);
}
#awwwards.honorable.silver.right a{
	background-image: url(/assets/images/awwwards/awwwards_honorable_silver_right.png);
}
#awwwards.honorable.white.left a{
	background-image: url(/assets/images/awwwards/awwwards_honorable_white_left.png);
}
#awwwards.honorable.white.right a{
	background-image: url(/assets/images/awwwards/awwwards_honorable_white_right.png);
}
#awwwards.honorable.green.left a{
	background-image: url(/assets/images/awwwards/awwwards_honorable_green_left.png);
}
#awwwards.honorable.green.right a{
	background-image: url(/assets/images/awwwards/awwwards_honorable_green_right.png);
}

@media only screen and (-Webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2),	only screen and (min-device-pixel-ratio: 1.5) {

	/* HONORABLE */

	#awwwards.honorable.black.left a{
		background-image: url(/assets/images/awwwards/awwwards_honorable_black_left@2x.png);
	}
	#awwwards.honorable.black.right a{
		background-image: url(/assets/images/awwwards/awwwards_honorable_black_right@2x.png);
	}
	#awwwards.honorable.silver.left a{
		background-image: url(/assets/images/awwwards/awwwards_honorable_silver_left@2x.png);
	}
	#awwwards.honorable.silver.right a{
		background-image: url(/assets/images/awwwards/awwwards_honorable_silver_right@2x.png);
	}
	#awwwards.honorable.white.left a{
		background-image: url(/assets/images/awwwards/awwwards_honorable_white_left@2x.png);
	}
	#awwwards.honorable.white.right a{
		background-image: url(/assets/images/awwwards/awwwards_honorable_white_right@2x.png);
	}
	#awwwards.honorable.green.left a{
		background-image: url(/assets/images/awwwards/awwwards_honorable_green_left@2x.png);
	}
	#awwwards.honorable.green.right a{
		background-image: url(/assets/images/awwwards/awwwards_honorable_green_right@2x.png);
	}

}
