// (c) Studio Verb

$navigator-ball-size: 7px;
$navigator-line-length: 25px;
$navigator-line-thickness: 1px;

@include element('page-case-study') {
  @include overflow(hidden);
  @include size(100% auto);

  @include name('cover') {
    @include align(fixed);
    @include display('boxv');
    @include padding(50px);
    @include size(100vw 100vh);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    perspective: 200px;

    @include transition(opacity transform, .4s, ease-out) {
      @include state('none', true) {
        transform: scale(1);
        opacity: 1;

        @include name('navigator') {
          div.ball {
            animation-play-state: running; } } }

      @include state('hidden') {
        transform: scale(.8);
        opacity: .5;

        @include name('navigator') {
          div.ball {
            animation-play-state: paused; } } } }

    @include name('navigator') {
      @include size(70vh);

      @include before-transition() {
        transform: translate3d(0, 0, 500px);
        opacity: 0; }

      div.top {
        @include align(top center);
        @include size($navigator-line-thickness $navigator-line-length);
        background: get-color('white'); }

      div.bottom {
        @include align(bottom center);
        @include size($navigator-line-thickness $navigator-line-length);
        background: get-color('white'); }

      div.left {
        @include align(left center);
        @include size($navigator-line-length $navigator-line-thickness);
        background: get-color('white'); }

      div.right {
        @include align(right center);
        @include size($navigator-line-length $navigator-line-thickness);
        background: get-color('white'); }

      div.ball {
        @include align();
        @include size($navigator-ball-size, true);
        @include edge(#{$navigator-ball-size / -2} null null #{($navigator-line-thickness -$navigator-ball-size) / 2});
        background: get-color('white');

        @include animation(navigator-ball, 1s, cubic-bezier(.98,.17,.15,.82), 0s, infinite) {
          @keyframes navigator-ball {
            0% {
              transform: translate3d(0, 0, 0); }
            25% {
              transform: translate3d(0, $navigator-line-length, 0); }
            50% {
              transform: translate3d(0, 0, 0); } } } } } }

  @include name('article') {
    @include size(100% auto);
    margin-top: 100vh;
    background: get-color('white', 1);
    transform: translate3d(0, 0, 0);

    @include transition(opacity transform, .4s, ease-out) {
      @include state('none', true) {
        transform: scale(.8);
        opacity: 0; }

      @include state('active') {
        transform: scale(1);
        opacity: 1; } }

    @include name('details') {
      @include display('boxv', top left);
      @include padding(100px 10vw);
      @include size(100% auto);
      transform: translate3d(0, 0, 0);

      @include name('title') {
        @include margin(0 0 0 0);
        @include max-size(500px none);
        color: get-color('black'); }

      @include name('summary') {
        @include font('s', 'l', 'l');
        @include margin(130px 0 30px 0);
        @include max-size(300px none);
        @include size(100% auto);

        + [name='services'] {
          @include margin(0); } }

      @include name('services') {
        @include margin(130px 0 0 0);
        @include max-size(300px none);
        @include size(100% auto);
        font-style: italic; }

      @include name('url-button') {
        @include button-style('url');
        @include margin(10px 0 0 0);

        @include labels() {
          @include font('ss', 's', 'n');
          color: get-color('greyish-blue');
          letter-spacing: 1.5px; } } } } }
