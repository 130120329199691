// (c) Studio Verb

body {
  @include transition(background, .4s, ease-out) {
    &, &.white {
      background: get-color('white'); }
    &.grey {
      background: get-color('light-grey'); } }

  &.scroll-lock {
    @include overflow(hidden); }
  &.scroll-lock-x {
    @include overflow(hidden visible); }
  &.scroll-lock-y {
    @include overflow(visible hidden); } }

#page {
  @include size(100%); }
